import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ตลาดซื้อขาย"
    }}>{`ตลาดซื้อขาย`}</h1>
    <hr></hr>
    <p>{`ตลาดซื้อขาย คือพื้นที่ที่สามารถซื้อตัวละครจากผู้เล่นคนอื่นได้ ถ้าไม่เชื่อดวงตัวเองในการเปิดกล่องสุ่มตัวละครว่าจะได้ตัวดี ๆ ละก็ ที่นี่แหละเหมาะสำหรับคุณ`}</p>
    <p><img alt="market" src={require("./public/images/market.png")} /></p>
    <h3 {...{
      "id": "ระบบของตลาดซื้อขาย"
    }}>{`ระบบของตลาดซื้อขาย`}</h3>
    <ul>
      <li parentName="ul">{`ซื้อตัวละคร`}</li>
      <li parentName="ul">{`ขายตัวละคร ( กดวางขายจากหน้าแดชบอร์ด )`}</li>
    </ul>
    <p>{`ราคาขั้นต่ำในการขายตัวละคร จะไม่สามารถต่ำกว่า 350 CLC`}</p>
    <h3 {...{
      "id": "ระยะเวลาของการขายตัวละคร"
    }}>{`ระยะเวลาของการขายตัวละคร`}</h3>
    <p>{`หลังจากที่ผู้เล่นวางขายตัวละครลงในตลาด จะมีระยะเวลาในการขาย 7 วัน โดยเมื่อครบระยะเวลา ตัวละครที่ผู้เล่นวางขาย จะถูกยกเลิกการขายออกจากตลาด ผู้เล่นจะต้องทำการวางขายตัวละครนั้นเข้าตลาดอีกครั้ง ทั้งนี้ ก็เพื่อลดภาระการทำงานและการรับส่งข้อมูลบน Marketplace Server`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      